<template>
  <div class="stickyHeadBar">
    <!-- <div class="announcementBar">
      <div class="db-container">
        <p><b>Important Announcement:</b> Meriwebshop.com is Now YeetCommerce.com!</p>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. <a href="#.">Update Theme</a></p>
        <button>
          <svg enable-background="new 0 0 32 32" id="Слой_1" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" id="Close"/>
            <g/><g/><g/><g/><g/><g/>
          </svg>
        </button>
      </div>
    </div> -->

    <div class="announcementBar" v-if="storeAnnouncementBar.length > 0" @mouseenter="handleMouseEnter('announcement-bar')" @mouseleave="handleMouseLeave('announcement-bar')">
      <div class="db-container">
        <swiper 
          :loop="storeAnnouncementBar.length > 1" 
          :autoplay="{delay:3000}" 
          :slides-per-view="1" 
          :space-between="0" 
          :speed="1000"
          ref="announcementBarSwiper"
        >
          <swiper-slide v-for="(storeAnnouncement,index) in storeAnnouncementBar" :key="`announcement-${index}`">
            <div class="swiper-data">
              <div class="swiper-content" v-html="storeAnnouncement.announcement.description"></div>
              <loaderBtn :stroke="'#fff'" v-if="deletingAnnouncement && deletingAnnouncement.id == storeAnnouncement.id && storeAnnouncementLoader"/>
              <button 
                type="button" 
                v-else-if="storeAnnouncement.announcement.closable == 1" 
                @click.prevent="acknowledgeAnnouncementHandle(storeAnnouncement)"
              >
                <svg enable-background="new 0 0 32 32" id="Слой_1" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <path d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" id="Close"/>
                  <g/><g/><g/><g/><g/><g/>
                </svg>
              </button>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="db-blue-section responsivePd">
      <div class="db-container">
        <div class="row">
          <div class="col-md-12 blue-section-content">
            
            <div class="mobie-nav-h opensideBarDashboard">
              <a href="#." @click.prevent="showSideBarDb" class="openSideBarBtn">
                <svg fill="#fff" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <title />
                  <g data-name="menu " id="menu_">
                    <path d="M29,6H3A1,1,0,0,0,3,8H29a1,1,0,0,0,0-2Z" />
                    <path d="M3,17H16a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z" />
                    <path d="M25,24H3a1,1,0,0,0,0,2H25a1,1,0,0,0,0-2Z" />
                  </g>
                </svg>
              </a>
              <div class="pageItemTitle" v-if="settingsLoader">
                <vue-skeleton-loader
                  type="rect"
                  :width="210"
                  :height="44"
                  animation="fade"
                />
              </div>
              <div class="pageItemTitle" id="component-name" v-else>
                <div 
                  class="tourBtn" 
                  id="tour-start-btn" 
                  data-toggle="tooltip" 
                  data-placement="bottom" 
                  title="Start Tour"
                  v-if="pageName != 'EDIT SHIPPING' &&
                  pageName != 'EDIT BILL' &&
                  pageName != 'SETTINGS' && 
                  pageName != 'HISTORY' &&
                  pageName != 'PROFILE' &&
                  pageName != 'HELP' &&
                  pageName != 'GALLERY' &&
                  pageName != 'THEME MANAGEMENT' &&
                  pageName != 'FACEBOOK SHOP' &&
                  pageName != 'GOOGLE SHOP' &&
                  pageName != 'SOCIAL MEDIA'"
                >
                  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" @click="startTour()">
                    <path d="M12 8V12M12 16H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  </svg>
                </div>
                <h2>{{ pageName }}</h2>
                <div 
                  class="addNewProductBtn" 
                  v-if="pageName != 'DASHBOARD' && 
                  pageName != 'PROFILE' &&
                  pageName != 'PAYMENT METHODS' && 
                  pageName != 'SEO' && 
                  pageName != 'REDIRECTS' &&
                  pageName != 'PAGE DETAILS' &&
                  pageName != 'ROBOTS' &&
                  pageName != 'SEARCH APPEARANCE' &&
                  pageName != 'THEME CUSTOMIZATION' &&
                  pageName != 'THEME BANNERS' &&
                  pageName != 'GALLERY' &&
                  pageName != 'FAQs' &&
                  pageName != 'SETTINGS' &&
                  pageName != 'DOMAINS' &&
                  pageName != 'SUBSCRIPTION' &&
                  pageName != 'PRIVACY POLICY' &&
                  pageName != 'TERMS & CONDITIONS' &&
                  pageName != 'THEME MANAGEMENT' &&
                  pageName != 'ADD BILL' &&
                  pageName != 'EDIT BILL' &&
                  pageName != 'HEADER NAVIGATION' &&
                  pageName != 'FOOTER NAVIGATION' && 
                  pageName != 'ADD SHIPPING' &&
                  pageName != 'EDIT SHIPPING' &&
                  pageName != 'HELP' &&
                  pageName != 'SMTP' &&
                  pageName != 'PIXEL MANAGEMENT' &&
                  pageName != 'FACEBOOK SHOP' &&
                  pageName != 'GOOGLE SHOP' &&
                  pageName != 'SOCIAL MEDIA' &&
                  pageName != 'MARKETING TOOLS' &&
                  pageName != 'DROP SHIPPING'"
                >
                  <button @click="createNew()" id="add-new-record" :disabled="getActiveTour()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.144 18.141">
                      <g id="Group_24769" data-name="Group 24769" transform="translate(-0.174 -0.175)">
                        <path id="Path_51703" data-name="Path 51703" d="M18.144,9.284a3.3,3.3,0,0,1-.127.4,1.556,1.556,0,0,1-1.509.977c-1.872,0-3.745,0-5.617,0h-.224v.225c0,1.878,0,3.756,0,5.634a1.593,1.593,0,1,1-3.187.008c0-1.878,0-3.756,0-5.634v-.233H7.261q-2.817,0-5.635,0A1.593,1.593,0,1,1,1.6,7.478c1.347-.006,2.693,0,4.04,0H7.477V7.252q0-2.746,0-5.492A1.6,1.6,0,0,1,8.828.018.132.132,0,0,0,8.859,0h.425a.128.128,0,0,0,.03.018A1.6,1.6,0,0,1,10.666,1.76q0,2.746,0,5.492v.225h5.842a1.528,1.528,0,0,1,1.363.717,3.841,3.841,0,0,1,.273.665Z" transform="translate(0.174 0.175)"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <!-- mobile-view Dashboard end-->

            <div class="blue-right-content">
              <div class="mobile-resp-icons subProFileBox">
                <ul v-if="settingsLoader">
                  <li>
                    <div class="searchBlock"> 
                      <vue-skeleton-loader
                        type="rect"
                        :width="383"
                        :height="44"
                        animation="fade"
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <vue-skeleton-loader
                        type="rect"
                        :width="40"
                        :height="44"
                        animation="fade"
                      />
                    </div>
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="40"
                      :height="44"
                      animation="fade"
                    />
                  </li>
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="157"
                      :height="44"
                      animation="fade"
                    />
                  </li>
                </ul>
                <ul v-else>
                  <li>
                    <div class="searchBlock" id="master-search">
                      <form @submit.prevent="">
                        <el-select
                          filterable
                          remote
                          reserve-keyword
                          placeholder="Search"
                          loading-text="Searching..."
                          no-data-text="Nothing found against the searched query."
                          @visible-change="changeHandle"
                          v-model="searchedItem"
                          :remote-method="asyncFind"
                          :fit-input-width="false"
                          :disabled="getActiveTour()"
                          :loading="isLoading"
                        >
                          <el-option-group v-for="(result,index) in results" :key="index" :label="result.module">
                            <el-option v-for="(item,i) in result.names" :key="i" :label="item.name.length > 50 ? `${item.name.substr(0, 50)}...` : item.name" :value="item">
                            </el-option>
                            <el-option v-if="result.names.length > 1 && result.module != 'Modules'" :label="`Search '${searchedKeyword}' in ${result.module}`" :value="getValue(result.names[0])">
                            </el-option>
                          </el-option-group>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49" slot="prefix">
                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" />
                          </svg>
                        </el-select>
                      </form>
                    </div>
                  </li>
                  <li>
                    <button class="addListBtn" id="add-new-dropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="getActiveTour()">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.144 18.141">
                        <g id="Group_24769" data-name="Group 24769" transform="translate(-0.174 -0.175)">
                          <path id="Path_51703" data-name="Path 51703" d="M18.144,9.284a3.3,3.3,0,0,1-.127.4,1.556,1.556,0,0,1-1.509.977c-1.872,0-3.745,0-5.617,0h-.224v.225c0,1.878,0,3.756,0,5.634a1.593,1.593,0,1,1-3.187.008c0-1.878,0-3.756,0-5.634v-.233H7.261q-2.817,0-5.635,0A1.593,1.593,0,1,1,1.6,7.478c1.347-.006,2.693,0,4.04,0H7.477V7.252q0-2.746,0-5.492A1.6,1.6,0,0,1,8.828.018.132.132,0,0,0,8.859,0h.425a.128.128,0,0,0,.03.018A1.6,1.6,0,0,1,10.666,1.76q0,2.746,0,5.492v.225h5.842a1.528,1.528,0,0,1,1.363.717,3.841,3.841,0,0,1,.273.665Z" transform="translate(0.174 0.175)" fill="#fff"/>
                        </g>
                      </svg>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="add-new-dropDown">
                      <button class="dropdown-item" @click.prevent="() => $parent.openForm()" v-if="pageName == 'PRODUCTS'">
                        Add New Product
                      </button>
                      <button  class="dropdown-item" @click.prevent="  $router.push({ name: 'product' , params:{isCreateNew: true} })" v-else>
                        Add New Product
                      </button>
                      <button class="dropdown-item" @click.prevent="$parent.createOrder()" v-if="pageName == 'ORDERS'" >
                        Add New Order
                      </button>
                      <button class="dropdown-item" @click.prevent=" $router.push({ name: 'order' , params:{isCreateNew: true} })" v-else>
                        Add New Order
                      </button>
                      <button class="dropdown-item" @click.prevent=" $parent.createCategory()" v-if="pageName == 'CATEGORIES'" >
                        Add New Category
                      </button>
                      <button class="dropdown-item" @click.prevent=" $router.push({ name: 'category' , params:{isCreateNew: true} })" v-else>
                        Add New Category
                      </button>
                    </div>
                  </li>
                  <li>
                    <button class="helpIconBtn" @click.prevent="helpRedirect()" id="help-link" :disabled="getActiveTour()">
                      <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                          <path d="M345.1,77.1C317.6,56.2,286.6,49,247.3,49c-29.8,0-55.3,6.1-75.5,19.7C142,89,128,123.1,128,177h76.8   c0-14.4-1.4-29.9,7-43.2c8.4-13.3,20.1-23.5,40.2-23.5c20.4,0,30.9,5.9,40.8,18.1c8.4,10.4,11.6,22.8,11.6,36   c0,11.4-5.8,21.9-12.7,31.4c-3.8,5.6-8.8,10.6-15.1,15.4c0,0-41.5,24.7-56.1,48.1c-10.9,17.4-14.8,39.2-15.7,65.3   c-0.1,1.9,0.6,5.8,7.2,5.8c6.5,0,56,0,61.8,0c5.8,0,7-4.4,7.1-6.2c0.4-9.5,1.6-24.1,3.3-29.6c3.3-10.4,9.7-19.5,19.7-27.3   l20.7-14.3c18.7-14.6,33.6-26.5,40.2-35.9c11.3-15.4,19.2-34.4,19.2-56.9C384,123.5,370.5,96.4,345.1,77.1z M242,370.2   c-25.9-0.8-47.3,17.2-48.2,45.3c-0.8,28.2,19.5,46.7,45.5,47.5c27,0.8,47.9-16.6,48.7-44.7C288.8,390.2,269,371,242,370.2z"/>
                        </g>
                      </svg>
                    </button>
                  </li>
                  <li class="dropdown">
                    <a href="#." id="user-options-dropdown" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" :class="!getActiveTour() ? '': 'disabled'" :style="getActiveTour() ? 'cursor:auto;':''">
                      <div class="subHeaderProfileImg">
                        <img 
                          :src=" $store.state.user && storeAdmin.image ? base_image_url + settings.store_id + '/user/' + storeAdmin.image : '/images/user-avatar-filled.svg' "
                        />
                      </div>
                      <p>
                        {{ $store.state.user ?  $store.state.username : '' }}
                      </p>
                      <span class="arrowIconbox">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10.724" height="5.362" viewBox="0 0 10.724 5.362">
                          <path id="dropDownArrow" d="M1873,51l5,5,5-5Z" transform="translate(-1872.638 -50.85)" stroke="#000" stroke-width="0.3"/>
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="user-options-dropdown">
                      <button class="dropdown-item" @click.prevent="dropDownClickHandle('profile')">
                        Profile
                      </button>
                      <button class="dropdown-item" @click.prevent="dropDownClickHandle('settings')">
                        Settings
                      </button>
                      <button  class="dropdown-item" v-loading.fullscreen.lock="logoutIsPending"  @click.prevent="$store.dispatch('logout');">
                        Logout
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
        </div>

        <!-- aanouncement popup starts here -->
        <div class="modalBg categoryResponsiveModal announcementModal" :class="checkAnnouncementPopups ? 'modal-active' : ''">
          <div class="modalLayers"></div>
          <div class="modalBody" style="width: 500px;">
            <div 
              class="oderSlideBox productSideBox" 
              style="position: relative; right: 0; width: 100%" 
              @mouseenter="handleMouseEnter('announcement-popup')" 
              @mouseleave="handleMouseLeave('announcement-popup')"
            >
              <swiper 
                :loop="storeAnnouncementPopup.length > 1" 
                :autoplay="{delay:3000}" 
                :slides-per-view="1" 
                :space-between="0" 
                :speed="1000"
                ref="announcementPopupSwiper"
              >
                <swiper-slide v-for="(storeAnnouncement,index) in storeAnnouncementPopup" :key="`announcement-popup-${index}`">
                  <div class="swiper-data popup">
                    <h5>{{ storeAnnouncement.announcement.heading  }}</h5>
                    <div class="swiper-content popup" v-html="storeAnnouncement.announcement.description"></div>
                    <loaderBtn :stroke="'#fff'" v-if="deletingAnnouncement && deletingAnnouncement.id == storeAnnouncement.id && storeAnnouncementLoader"/>
                    <button 
                      type="button" 
                      v-else-if="storeAnnouncement.announcement.closable == 1" 
                      @click.prevent="acknowledgeAnnouncementHandle(storeAnnouncement)"
                    >
                      Acknowledge
                    </button>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="modalCloseIcon">
              <button type="button" @click.prevent="closeAnnouncementPopupHandle()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14.026 14.027"
                >
                  <path
                    id="cross-small"
                    d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z"
                    transform="translate(-5.483 -5.483)"
                    fill="#fd4d5d"
                    stroke="#fd4d5d"
                    stroke-width="0.3"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <!-- aanouncement popup ends here -->

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { MessageBox, Message } from 'element-ui'
import loaderBtn from "@/components/loaderBtn.vue";
import { Navigation, Pagination, Autoplay } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide, } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination, Autoplay])

export default {
  name:'subHeader',
  components:{
    loaderBtn,
    Swiper,
    SwiperSlide
  },
  data: () => ({
    base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
    searchedItem: '',
    searchedKeyword:'',
    results:[],
    timer: undefined,
    isLoading:false,
    storeAdmin:{
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      mobile_no: '',
      city:'',
      address_line_1: '',
      address_line_2: '',
      image: '',
    },

    //announcements start
    storeAnnouncementBar:[],
    storeAnnouncementPopup:[],
    storeAnnouncementLoader:false,
    deletingAnnouncement:null,
    //announcements end

  }),
  props: ["pageName"],
  methods: {
    async asyncFind(query){
      
      this.searchedKeyword = query;

      let sortByModule = null;

      if(this.isUpdatedUIModule()){

        sortByModule = this.pageName;

      }

      if(query.length > 0){
        
        this.isLoading = true;

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {

          this.$axios.get(`/dashboard/search/all`,{
            params:{
              keyword: query,
              sortBy: sortByModule
            }
          }).then(resp =>{

            if(resp.data.result.length > 0){
              
              this.results = _.cloneDeep(resp.data.result);
            
            }else{

              this.results =[];

            }

            this.isLoading = false;

          })
        },500)

      }else{

        this.results = [];

      }

    },
    changeHandle(dropDownIsClose){

      if(!dropDownIsClose){

        this.results = [];
        this.searchedItem = '';

      }
      
    },
    getValue(result){

      let tempValue = {
        name: this.searchedKeyword,
        slug: result.slug,
        searchInModule: 1,
      }

      return tempValue;

    },
    dropDownClickHandle(option){

      if(option == 'profile'){

        if(this.$route.path != '/settings/profile'){

          this.$router.push({ path: '/settings/profile' });

        }

      }else if(option == 'settings'){


        if(this.$route.path != '/settings/store-settings'){

          this.$router.push({ path: '/settings/store-settings' });

        }

      }

    },
    showSideBarDb(){

      var element = document.getElementsByTagName("body")[0];

      if(element.classList.contains('hideSideBar')){

        element.classList.remove("hideSideBar");
        element.classList.add("activeIpad");

      }else if(element.classList.contains('activeIpad')){

        element.classList.remove("activeIpad");
        element.classList.add("hideSideBar");

      }

    },
    internalSearchHandle(option){

      if(this.pageName == 'ORDERS'){

        this.$parent.filters.searchedKeyword = option.name;

        if(option.searchInModule && option.searchInModule == 1){

          this.$parent.filterOrdersInternalSearch(false);

        }else{

          this.$parent.filterOrdersInternalSearch(true);

        }

      }
      if(this.pageName == "CATEGORIES"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchCategory();

      }
      if(this.pageName == 'BRANDS'){

        this.$parent.searchedItem = option.name;


        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchBrand();

      }
      if(this.pageName == 'PRODUCT BUNDLES'){

        this.$parent.filter.searchedTerm = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchProductBundle();

      }
      if(this.pageName == "CUSTOMERS"){

        this.$parent.searchedCustomer = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchCustomer();

      }
      if(this.pageName == 'SHIPPINGS'){

        this.$parent.searchedItem = option.name;

        this.$parent.searchShippingRegion();

      }
      if(this.pageName == 'COUPONS'){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchCoupon();

      }
      if(this.pageName == "PAGE DETAILS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchPageDetail();

      }
      if(this.pageName == "NO INDEX URLS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchNoIndexUrl();
        
      }
      if(this.pageName == "BLOG CATEGORIES"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchBlogCategory();

      }
      if(this.pageName == "BLOGS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchBlog();

      }
      if(this.pageName == "ACCOUNTS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchAccount();

      }
      if(this.pageName == "EXPENSES"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchExpense();

      }
      if(this.pageName == "VENDORS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchVendor();

      }
      if(this.pageName == "BILLS"){

        this.$parent.searchedItem = option.name;

        this.$parent.searchBill();

      }
      if(this.pageName == "NOTIFICATIONS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchNotification();

      }
      if(this.pageName == "SUPPORT MEMBERS"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchSupportMember();

      }
      if(this.pageName == "USER MANAGEMENT"){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchUser();

      }
      if(this.pageName == 'CONTACTS'){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchContact();

      }
      if(this.pageName == 'GROUPS'){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchGroup();

      }
      if(this.pageName == 'EMAILS'){

        this.$parent.searchedItem = option.name;

        if(!option.searchInModule){

          this.$parent.isMasterSearched = true;

        }

        this.$parent.searchEmail();

      }
      
    },
    helpRedirect(){

      if(!this.getActiveTour()){

        this.$router.push('/help');
        
      }

    },
    createNew(){

      if(!this.getActiveTour()){

        if(this.pageName == 'ORDERS'){

          this.$parent.createOrder();

        }
        if(this.pageName == "CUSTOMERS"){

          this.$parent.createCustomer();

        }
        if(this.pageName == "CATEGORIES"){

          this.$parent.createCategory();

        }
        if(this.pageName == "BRANDS"){

          this.$parent.createBrand();

        }
        if(this.pageName == "PRODUCT BUNDLES"){

          this.$parent.createBundle();

        }
        if(this.pageName == "SHIPPINGS"){

          this.$router.push('/shipping/add');

        }
        if(this.pageName == "COUPONS"){

          this.$parent.createCoupon();

        }
        if(this.pageName == "NO INDEX URLS"){

          this.$parent.createNoIndexUrl();

        }
        if(this.pageName == "BLOGS"){

          this.$parent.createBlog();

        }
        if(this.pageName == "BLOG CATEGORIES"){

          this.$parent.createBlogCategory();

        }
        if(this.pageName == "BILLS"){

          this.$router.push('/expenses-purchases/bill/add');

        }
        if(this.pageName == "VENDORS"){

          this.$parent.createVendor();

        }
        if(this.pageName == "EXPENSES"){

          this.$parent.createExpense();

        }
        if(this.pageName == "ACCOUNTS"){

          this.$parent.createAccount();

        }
        if(this.pageName == "NOTIFICATIONS"){

          this.$parent.createNotification();

        }
        if(this.pageName == "SUPPORT MEMBERS"){

          this.$parent.createSupportMember();

        }
        if(this.pageName == "USER MANAGEMENT"){

          this.$parent.createUser();

        }
        if(this.pageName == "CONTACTS"){

          this.$parent.createContact();

        }
        if(this.pageName == "GROUPS"){

          this.$parent.createGroup();

        }
        if(this.pageName == "EMAILS"){

          this.$parent.createEmail();
          
        }

      }

    },
    isUpdatedUIModule(){

      if(this.pageName == "ORDERS" || this.pageName == "CATEGORIES" || this.pageName == "BRANDS" ||
      this.pageName == "CUSTOMERS" || this.pageName == "SHIPPINGS" || this.pageName == "COUPONS" ||
      this.pageName == "PAGE DETAILS" || this.pageName == "NO INDEX URLS" || this.pageName == "BLOG CATEGORIES" || 
      this.pageName == "BLOGS" || this.pageName == "BILLS" || this.pageName == "ACCOUNTS" || 
      this.pageName == "EXPENSES" || this.pageName == "VENDORS" || this.pageName == "NOTIFICATIONS" || 
      this.pageName == "SUPPORT MEMBERS" || this.pageName == "USER MANAGEMENT" || this.pageName == "CONTACTS" || 
      this.pageName == "GROUPS" || this.pageName == "EMAILS" || this.pageName == "PRODUCT BUNDLES"){

        return true;

      }else{

        return false;

      }

    },

    //announcement handling start
    acknowledgeAnnouncementHandle(listedAnnouncement){

      MessageBox.confirm(
        'Do you really want to remove the announcement? Acknowledging the statement made in the announcement can positively impact your experience.',
        'Warning',
        {
          type: 'warning',
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
        }
      ).then(async ()=>{

        let formData = new FormData();

        formData.append('store_announcement_id',listedAnnouncement.id);

        this.deletingAnnouncement = listedAnnouncement;

        this.storeAnnouncementLoader = true
        try{
          let res = await this.$axios.post('/announcement/acknowledge',formData);
          if(res.data.status_code == "1806"){

            this.$notify({
              type: 'success',
              title: 'Success',
              message: res.data.message,
            });
            
            this.storeAnnouncementBar = [];

            this.storeAnnouncementPopup = [];

            this.$store.dispatch("settings_module/fetchStoreSetting");

          }
        }catch(error){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response ? error.response.data.message : error.message, 
          });

        }finally{

          this.deletingAnnouncement = null;

          this.storeAnnouncementLoader = false;

        }

      }).catch(() => {})

    },
    handleMouseEnter(announcementType){

      if(announcementType == 'announcement-bar'){

        this.$refs.announcementBarSwiper.$el.swiper.autoplay.stop();

      }else if(announcementType == 'announcement-popup'){

        this.$refs.announcementPopupSwiper.$el.swiper.autoplay.stop();
        
      }

    },
    handleMouseLeave(announcementType){

      if(announcementType == 'announcement-bar'){

        this.$refs.announcementBarSwiper.$el.swiper.autoplay.start();

      }else if(announcementType = 'announcement-popup'){

        this.$refs.announcementPopupSwiper.$el.swiper.autoplay.start();

      }

    },
    closeAnnouncementPopupHandle(){

      MessageBox.confirm(
        'Do you really want to close the announcement popup?. You can acknowledge all closable announcements or close for now.',
        'Warning',
        {
          type: 'warning',
          confirmButtonText: 'Acknowledge',
          cancelButtonText: "Don't show again",
          customClass: "popupConfirmation",
          beforeClose: async (action, instance, done) => {

            if(action === 'confirm'){

              instance.confirmButtonLoading = true;
              instance.confirmButtonText = 'Loading...';

              let announcementList = [];

              this.storeAnnouncementPopup.forEach((listedAnnouncement) => {

                announcementList.push(listedAnnouncement.id);

              });

              let formData = new FormData();

              formData.append('store_announcements',JSON.stringify(announcementList));

              try{
                let res = await this.$axios.post('/announcement/acknowledge/bulk',formData);
                if(res.data.status_code == "1806"){

                  this.$notify({
                    type: 'success',
                    title: 'Success',
                    message: res.data.message,
                  });

                  this.storeAnnouncementBar = [];

                  this.storeAnnouncementPopup = [];

                  this.$store.dispatch("settings_module/fetchStoreSetting");

                }
              }catch(error){

                this.$message({
                  type: "error",
                  showClose: true,
                  message: error.response ? error.response.data.message : error.message,
                });

              }finally{

                instance.confirmButtonLoading = false;
                instance.confirmButtonText = 'Acknowledge';

                done();

              }

            }else{

              this.$store.commit("settings_module/update_announcement_popup_close",true);

              done();

            }

          }
        }
      ).then(async ()=>{ 
        
      }).catch(() => {})

    },
    //announcement handling end

    //tour
    startTour(){

      if(this.pageName == 'DASHBOARD'){

        this.$parent.startDashboardTour();

      }

      if(this.pageName == 'CATEGORIES'){

        this.$parent.startCategoryTour();

      }

      if(this.pageName == "PRODUCT BUNDLES"){

        this.$parent.startProductBundlesTour();

      }

      if(this.pageName == 'BRANDS'){

        this.$parent.startBrandTour();

      }

      if(this.pageName == 'CUSTOMERS'){

        this.$parent.startCustomersTour();

      }

      if(this.pageName == 'ORDERS'){

        this.$parent.startOrdersTour();

      }

      if(this.pageName == 'SHIPPINGS'){

        this.$parent.startShippingTour();

      }

      if(this.pageName == 'ADD SHIPPING'){

        this.$parent.$parent.startAddShippingTour();

      }

      if(this.pageName == 'COUPONS'){

        this.$parent.startCouponsTour();

      }

      if(this.pageName == 'MARKETING TOOLS'){
       
        this.$parent.startMarketingToolsTour();
      }
      
      if(this.pageName == 'DROP SHIPPING'){

        this.$parent.startDropshippingTour();

      }

      if(this.pageName == 'PAGE DETAILS'){

        this.$parent.startPageDetailsTour();

      }

      if(this.pageName == 'NO INDEX URLS'){

        this.$parent.startNoIndexUrlsTour();

      }

      if(this.pageName == 'BLOG CATEGORIES'){

        this.$parent.startBlogCategoriesTour();

      }

      if(this.pageName == 'FAQs'){

        this.$parent.startFAQsTour();

      }

      if(this.pageName == 'BLOGS'){

        this.$parent.startBlogsTour();

      }

      if(this.pageName == 'BILLS'){

        this.$parent.startBillsTour();

      }

      if(this.pageName == 'ADD BILL'){

        this.$parent.startAddBillsTour();

      }

      if(this.pageName == "VENDORS"){

        this.$parent.startVendorsTour();

      }

      if(this.pageName == "EXPENSES"){

        this.$parent.startExpensesTour();

      }

      if(this.pageName == "ACCOUNTS"){

        this.$parent.startAccountsTour();

      }

      if(this.pageName == "SUPPORT MEMBERS"){

        this.$parent.startChatSupportTour();

      }

      if(this.pageName == "USER MANAGEMENT"){

        this.$parent.startUserManagementTour();

      }

      if(this.pageName == "THEME MANAGEMENT"){

        this.$parent.startThemeManagementTour();

      }

      if(this.pageName == 'SUBSCRIPTION'){

        this.$parent.startSubscriptionsTour();

      }

      if(this.pageName == "DOMAINS"){

        this.$parent.startDomainSetupTour();

      }

      if(this.pageName == "PRIVACY POLICY"){

        this.$parent.startPrivacyPolicyTour();

      }

      if(this.pageName == "TERMS & CONDITIONS"){

        this.$parent.startPrivacyPolicyTour();

      }

      if(this.pageName == 'NOTIFICATIONS'){

        this.$parent.startNotificationsTour();

      }

      if(this.pageName == 'SEO'){

        this.$parent.startseoGeneralsTour();

      }

      if(this.pageName == 'REDIRECTS'){

        this.$parent.startSeoRedirectsTour();

      }

      if(this.pageName == 'ROBOTS'){

        this.$parent.startSeoRobotTour();

      }

      if(this.pageName == 'SEARCH APPEARANCE'){

        this.$parent.startSeoSearchAppearanceTour();

      }

      if(this.pageName == 'HEADER NAVIGATION'){

        this.$parent.startHeaderNavigationTour();

      }

      if(this.pageName == 'FOOTER NAVIGATION'){

        this.$parent.startFooterNavigationTour();

      }

      if(this.pageName == 'THEME CUSTOMIZATION'){

        this.$parent.startContentBlocksTour();

      }

      if(this.pageName == 'THEME BANNERS'){

        this.$parent.startHomeBannerBlocksTour();

      }

      if(this.pageName == 'PAYMENT METHODS'){

        this.$parent.startPaymentModuleTour();

      }

      if(this.pageName == "CONTACTS"){

        this.$parent.startContactsTour();

      }

      if(this.pageName == "GROUPS"){

        this.$parent.startGroupsTour();

      }

      if(this.pageName == "EMAILS"){

        this.$parent.startEmailsTour();

      }

      if(this.pageName == 'SMTP'){

        this.$parent.startSmtpTour();

      }

      if(this.pageName == 'PIXEL MANAGEMENT'){

        this.$parent.startPixelManagementTour();

      }

    },
    getActiveTour(){

      if(this.pageName == 'DASHBOARD' || this.pageName == 'CATEGORIES' ||
      this.pageName == "PRODUCT BUNDLES" || this.pageName == 'BRANDS' ||
      this.pageName == 'CUSTOMERS' || this.pageName == 'ORDERS' ||
      this.pageName == 'SHIPPINGS' || this.pageName == 'COUPONS' ||
      this.pageName == 'MARKETING TOOLS' || this.pageName == 'DROP SHIPPING' ||
      this.pageName == 'PAGE DETAILS' || this.pageName == 'NO INDEX URLS' ||
      this.pageName == 'BLOG CATEGORIES' || this.pageName == 'FAQs' ||
      this.pageName == 'BLOGS' || this.pageName == 'BILLS' ||
      this.pageName == 'ADD BILL' || this.pageName == 'VENDORS' ||
      this.pageName == 'EXPENSES' || this.pageName == "ACCOUNTS" ||
      this.pageName == 'SUPPORT MEMBERS' || this.pageName == 'USER MANAGEMENT' ||
      this.pageName == 'SUBSCRIPTION' || this.pageName == 'DOMAINS' ||
      this.pageName == 'PRIVACY POLICY' || this.pageName == 'TERMS & CONDITIONS' ||
      this.pageName == 'NOTIFICATIONS' || this.pageName == 'SEO' ||
      this.pageName == 'REDIRECTS' || this.pageName == 'ROBOTS' ||
      this.pageName == 'SEARCH APPEARANCE' || this.pageName == 'HEADER NAVIGATION' ||
      this.pageName == 'FOOTER NAVIGATION' || this.pageName == 'THEME CUSTOMIZATION' ||
      this.pageName == 'THEME BANNERS' || this.pageName == 'PAYMENT METHODS' ||
      this.pageName == 'CONTACTS' || this.pageName == "GROUPS" || 
      this.pageName == "EMAILS" || this.pageName == "SMTP" ||
      this.pageName == "PIXEL MANAGEMENT"){

        return this.$parent.tourIsActive;

      }else if(this.pageName == 'ADD SHIPPING'){

        if(this.$tours.addShippingTour.currentStep != -1){

          return true;

        }else{

          return false;

        }

      }

    },
  },
  computed: {
    ...mapGetters({
      settings: "settings_module/settings",
      settingsLoader: "settings_module/isPending",
      logoutIsPending:'logoutIsPending',
      isAnnouncementPopupClosed:'settings_module/announcementPopupStatus',
    }), 
    checkAnnouncementPopups(){

      if((this.storeAnnouncementPopup.length > 0) && !this.isAnnouncementPopupClosed){

        return true;

      }else{

        return false;

      }

    },
  },
  watch:{
    searchedItem:{
      handler:function(value){

        let selectedDropDownItem = value

        let option = null;

        this.results.forEach(result => {

          let found = result.names.find(x=> x.name == selectedDropDownItem.name && x.slug == selectedDropDownItem.slug);

          if(found){

            option = result;
            option.names = [];

            option.names.push(found);

            return

          }

        });

        if(option){

          if(option.module != "Modules"){
            
            if(option.module == "Orders"){
              
              if(this.$route.path != option.names[0].slug){

                selectedDropDownItem.name = selectedDropDownItem.name.split(' - ')[0];

                let payload = {
                  keyword: selectedDropDownItem.name,
                };
                
                this.$store.commit("orders_module/update_searched_keyword", payload);

                this.$router.push({path: option.names[0].slug , query:{search: selectedDropDownItem.name, preview: 1}});

              }else{
                
                selectedDropDownItem.name = selectedDropDownItem.name.split(' - ')[0];

                this.internalSearchHandle(selectedDropDownItem);

              }

            }else{

              if(this.$route.path != option.names[0].slug){

                this.$router.push({path: option.names[0].slug , query:{search: selectedDropDownItem.name, preview: 1}});

              }else{

                this.internalSearchHandle(selectedDropDownItem);
                
              }

            }
            
          }else{

            if(this.$route.path != option.names[0].slug){

              this.$router.push({path: option.names[0].slug});

            }

          }

        }else if(value.searchInModule){

          if(this.$route.path != value.slug){

            this.$router.push({path: value.slug , query:{search: value.name, preview: 0}});

          }else{

            this.internalSearchHandle(value);

          }

        }

      },deep:true
    },
    settings:{
      handler:function(value){

        if(value && value.store_announcements && value.store_announcements.length > 0){

          let tempAnnouncementBars = [];

          let tempAnnouncementPopup = [];

          value.store_announcements.forEach((storeAnnouncement) => {

            if(storeAnnouncement.announcement.type == 'announcement_bar'){

              tempAnnouncementBars.push(storeAnnouncement);

            }else if(storeAnnouncement.announcement.type == 'popup'){

              tempAnnouncementPopup.push(storeAnnouncement);

            }

          });

          this.storeAnnouncementBar = _.cloneDeep(tempAnnouncementBars);

          this.storeAnnouncementPopup = _.cloneDeep(tempAnnouncementPopup);

        }

      },deep:true
    }
  },
  mounted(){

  },
  async beforeMount(){

    this.storeAdmin = _.cloneDeep(this.$store.state.user);

    if((!this.settings || this.settings.length == 0 || this.settings.error) && !this.settingsLoader){

      this.$store.dispatch("settings_module/fetchStoreSetting");
      
    }else{

      if(this.settings.store_announcements && this.settings.store_announcements.length > 0){

        let tempAnnouncementBars = [];

        let tempAnnouncementPopup = [];

        this.settings.store_announcements.forEach((storeAnnouncement) => {

          if(storeAnnouncement.announcement.type == 'announcement_bar'){

            tempAnnouncementBars.push(storeAnnouncement);

          }else if(storeAnnouncement.announcement.type == 'popup'){

            tempAnnouncementPopup.push(storeAnnouncement);

          }

        });

        this.storeAnnouncementBar = _.cloneDeep(tempAnnouncementBars);

        this.storeAnnouncementPopup = _.cloneDeep(tempAnnouncementPopup);

      }

    }

  },
};
</script>

<style>
  .helpIconBtn{
    width: 40px;
    height: 40px;
    background-color: #1ec2c2;
    border-radius: 50%;
    border: none;
    padding: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .helpIconBtn svg{
    fill: #fff;
    width: 100%;
    height: 100%;
  }
  .subHeaderProfileImg {
    width: 40px;
    height: 40px;
  }
  .subHeaderProfileImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .subProFileBox ul {
    margin-bottom: 0px !important;
  }
  .blue-right-content ul {
    align-items: center;
    display: flex;
    font-size: 20px;
  }
  .subProFileBox ul li {
    padding: 0px;
  }
  .subProFileBox ul li a p {
    font-size: 14px;
    color: #000000;
    margin-bottom: 0px;
    font-weight: 400;
    margin: 0px 12px;
  }
  .subProFileBox ul li a .arrowIconbox {
    display: inline-block;
    width: 10px;
    height: 5px;
  }
  .subProFileBox ul li a .arrowIconbox svg {
    width: 100%;
    height: 100%;
    display: block;
  }
  .subProFileBox ul li a {
    display: inline-flex;
    align-items: center;
  }
  .subProFileBox ul li button.addListBtn,
  .subProFileBox ul li a.addListBtn {
    width: 40px;
    height: 40px;
    background-color: #fd4d5d;
    border-radius: 50%;
    border: none;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  .subProFileBox ul li button.addListBtn svg,
  .subProFileBox ul li a.addListBtn svg {
    width: 100%;
    height: 100%;
  }
  .searchBlock {
    width: 383px;
    margin-right: 15px;
  }
  .searchBlock form {
    position: relative;
  }
  .searchBlock form .searchIconWrap {
    width: 17.49px;
    height: 17.49px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchBlock form .searchIconWrap svg {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  .searchBlock form input {
    background-color: #fff;
    padding: 10px 55px 10px 45px;
    border: 1px solid #707070;
    font-size: 14px;
    color: #848484;
    width: 100%;
    border-radius: 40px;
    line-height: 18px;
  }
  .filterBtnWrap {
    background-color: #f8f8f8;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    right: 3px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .filterBtnWrap svg {
    width: 21px;
    height: 19px;
    fill: #15223d;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .filterBtnWrap:hover {
    background-color: #15223d;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .filterBtnWrap:hover svg {
    fill: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  /* el select styling */
  .el-select{
    width: 100%;
  }
  .el-input__prefix{
    display: flex;
    align-items: center;
    justify-content: center;
    left: 15px;
    width: 17.49px;
  }
  .el-input__prefix svg{
    fill:#848484;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .is-focus .el-input__prefix svg{
    fill: #409EFF;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .el-select-dropdown.el-popper{
    z-index: 99999999 !important;
  }
  .el-input__inner{
    padding-left: 45px !important;
  }
  /* .el-select .el-input .el-select__caret::before {
    content: "\e6e1";
    color: #848484;
  }
  .el-select .el-input.is-focus .el-select__caret {     
    transform: rotateZ(0deg);
  }
  .is-focus .el-input__suffix .el-select__caret::before{
    color: #409EFF;
  } */

  /* .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .popupConfirmation{
    width:600px;
  }
</style>
